import { render, staticRenderFns } from "./edit-and-create.vue?vue&type=template&id=570b6142&"
import script from "./edit-and-create.vue?vue&type=script&lang=js&"
export * from "./edit-and-create.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CText: require('@chakra-ui/vue').CText, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CImage: require('@chakra-ui/vue').CImage, CAccordionIcon: require('@chakra-ui/vue').CAccordionIcon, CAccordionHeader: require('@chakra-ui/vue').CAccordionHeader, CRadioButtonGroup: require('@chakra-ui/vue').CRadioButtonGroup, CAccordionPanel: require('@chakra-ui/vue').CAccordionPanel, CAccordionItem: require('@chakra-ui/vue').CAccordionItem, CAccordion: require('@chakra-ui/vue').CAccordion, CInput: require('@chakra-ui/vue').CInput})
