var render = function () {
  var _vm$data$foodGroup, _vm$data$nutritionCon, _vm$data$nutritionCon2, _vm$data11, _vm$data11$nutritionC, _vm$data11$nutritionC2, _vm$data11$nutritionC3, _vm$data15, _vm$data15$nutritionC, _vm$data15$nutritionC2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1",
      "min-width": "0"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "align-items": "center",
      "margin-bottom": "20px",
      "gap": "48px"
    }
  }, [_c('c-button', {
    attrs: {
      "margin-top": "17.5px",
      "variant": "ghost",
      "display": "flex",
      "align-items": "center",
      "gap": "1rem"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('c-image', {
    attrs: {
      "w": "24px",
      "h": "24px",
      "object-fit": "cover",
      "src": require('@/assets/icon-chevron-left.svg')
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Kembali ")])], 1), _c('BreadcrumbPath', {
    attrs: {
      "paths": [{
        label: 'Manajemen Nilai Gizi',
        href: {
          name: 'admin.glossaries'
        }
      }, {
        label: _vm.isEditPage ? 'Edit Nilai Gizi' : 'Tambah Nilai Gizi',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px 80px'],
      "min-height": ['unset', '74vh'],
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "max-width": "800px",
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" " + _vm._s(_vm.isEditPage ? 'Edit Nilai Gizi' : 'Tambah Nilai Gizi') + " ")]), _c('c-box', {
    attrs: {
      "as": "form",
      "margin-top": "70px",
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "160px",
      "justify-content": "center"
    }
  }, [_c('BaseInputPhotos', {
    attrs: {
      "label": "Gambar Makanan",
      "photos": _vm.data.photoUrl,
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.photoUrl),
      "invalid-text": _vm.parseErrors('Gambar Makanan', _vm.$v.data.photoUrl)
    },
    on: {
      "update:photos": function updatePhotos(newValue) {
        return _vm.data.photoUrl = newValue;
      },
      "blur": _vm.$v.data.photoUrl.$touch
    }
  })], 1), _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('BaseInputAutocomplete', {
    attrs: {
      "label": "Golongan Makanan",
      "placeholder": "Masukkan Golongan Makanan",
      "full-width": "",
      "is-required": "",
      "options": _vm.optionGroups,
      "is-invalid": _vm.isInvalidField(_vm.$v.data.foodGroup),
      "invalid-text": _vm.parseErrors('Golongan Makanan', _vm.$v.data.foodGroup)
    },
    on: {
      "blur": _vm.$v.data.foodGroup.$touch
    },
    model: {
      value: _vm.data.foodGroup,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "foodGroup", $$v);
      },
      expression: "data.foodGroup"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Nama Makanan",
      "placeholder": "Masukkan Nama Makanan",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.foodName),
      "invalid-text": _vm.parseErrors('Nama Makanan', _vm.$v.data.foodName)
    },
    on: {
      "blur": _vm.$v.data.foodName.$touch
    },
    model: {
      value: _vm.data.foodName,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "foodName", $$v);
      },
      expression: "data.foodName"
    }
  })], 1)], 1), ((_vm$data$foodGroup = _vm.data.foodGroup) === null || _vm$data$foodGroup === void 0 ? void 0 : _vm$data$foodGroup.toUpperCase()) === 'ONE-DISH MEAL' ? _c('BaseInputTextarea', {
    attrs: {
      "label": "Komposisi (Per Porsi)",
      "placeholder": "Masukkan Komposisi (Per Porsi)",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.foodIngredient),
      "invalid-text": _vm.parseErrors('Golongan Makanan', _vm.$v.data.foodIngredient)
    },
    on: {
      "blur": _vm.$v.data.foodIngredient.$touch
    },
    model: {
      value: _vm.data.foodIngredient,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "foodIngredient", $$v);
      },
      expression: "data.foodIngredient"
    }
  }) : _vm._e(), _c('BaseInputAutocomplete', {
    attrs: {
      "label": "Nama Bahan Dasar Makanan",
      "placeholder": "Masukkan Nama Bahan Dasar Makanan",
      "full-width": "",
      "is-required": "",
      "can-manual-input": false,
      "options": _vm.optionBasicIngredients,
      "is-invalid": _vm.isInvalidField(_vm.$v.data.basicFoodIngredient),
      "invalid-text": _vm.parseErrors('Nama Bahan Dasar Makanan', _vm.$v.data.basicFoodIngredient)
    },
    on: {
      "blur": _vm.$v.data.basicFoodIngredient.$touch
    },
    model: {
      value: _vm.data.basicFoodIngredient,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "basicFoodIngredient", $$v);
      },
      expression: "data.basicFoodIngredient"
    }
  }), _c('c-flex', {
    attrs: {
      "gap": "32px"
    }
  }, [_c('BaseInputText', {
    attrs: {
      "label": "Takaran Saji/Porsi",
      "placeholder": "Masukkan Takaran Saji",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.servingSizePerPortion.servingSizePerPortion),
      "invalid-text": _vm.parseErrors('Takaran Saji', _vm.$v.data.servingSizePerPortion.servingSizePerPortion),
      "input-right-addon-without-margin": ""
    },
    on: {
      "blur": _vm.$v.data.servingSizePerPortion.servingSizePerPortion.$touch,
      "focus": function focus($event) {
        var _vm$data, _vm$data$servingSizeP;

        return _vm.selectAllText($event, (_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : (_vm$data$servingSizeP = _vm$data.servingSizePerPortion) === null || _vm$data$servingSizeP === void 0 ? void 0 : _vm$data$servingSizeP.servingSizePerPortion);
      }
    },
    scopedSlots: _vm._u([{
      key: "input-right-addon-element",
      fn: function fn() {
        return [_c('c-button', {
          attrs: {
            "height": "100%",
            "background-color": "transparent",
            "font-size": "18px",
            "font-weight": "500"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalUnit = 'servingSizePerPortion';
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.data.servingSizePerPortion.servingSizeUnitPerPortion) + " "), _c('c-box', {
          attrs: {
            "margin-left": "10px"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-arrow-down.svg'),
            "height": "15",
            "width": "15"
          }
        })], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.data.servingSizePerPortion.servingSizePerPortion,
      callback: function callback($$v) {
        _vm.$set(_vm.data.servingSizePerPortion, "servingSizePerPortion", $$v);
      },
      expression: "data.servingSizePerPortion.servingSizePerPortion"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Ukuran Rumah Tangga/Porsi",
      "placeholder": "Masukkan Ukuran Rumah Tangga",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.householdMeasurementPerPortion.householdMeasurementPerPortion),
      "invalid-text": _vm.parseErrors('Ukuran Rumah Tangga', _vm.$v.data.householdMeasurementPerPortion.householdMeasurementPerPortion),
      "input-right-addon-without-margin": "",
      "is-centered": ""
    },
    on: {
      "blur": _vm.$v.data.householdMeasurementPerPortion.householdMeasurementPerPortion.$touch,
      "focus": function focus($event) {
        var _vm$data2, _vm$data2$householdMe;

        return _vm.selectAllText($event, (_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$householdMe = _vm$data2.householdMeasurementPerPortion) === null || _vm$data2$householdMe === void 0 ? void 0 : _vm$data2$householdMe.householdMeasurementPerPortion);
      }
    },
    scopedSlots: _vm._u([{
      key: "input-left-icon-element",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "as": "button",
            "type": "button",
            "justify-content": "center",
            "align-items": "center",
            "width": "40px",
            "height": "100%",
            "border-right-width": "1px",
            "border-style": "solid",
            "border-color": _vm.isInvalidField(_vm.$v.data.householdMeasurementPerPortion.householdMeasurementPerPortion) ? 'danger.400' : 'inherit',
            "border-top-left-radius": "6px",
            "border-bottom-left-radius": "6px",
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.onUpdateHouseholdMeasurement(-0.25, 'householdMeasurementPerPortion');
            }
          }
        }, [_c('c-box', {
          attrs: {
            "width": "15px",
            "height": "auto"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-minus.svg'),
            "height": "100%",
            "width": "100%",
            "fill": _vm.isInvalidField(_vm.$v.data.householdMeasurementPerPortion.householdMeasurementPerPortion) ? '#D32737' : '#008C81'
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "input-right-icon-element",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "as": "button",
            "type": "button",
            "justify-content": "center",
            "align-items": "center",
            "width": "40px",
            "height": "100%",
            "border-left-width": "1px",
            "border-style": "solid",
            "border-color": _vm.isInvalidField(_vm.$v.data.householdMeasurementPerPortion.householdMeasurementPerPortion) ? 'danger.400' : 'inherit',
            "border-top-right-radius": "0",
            "border-bottom-right-radius": "0",
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.onUpdateHouseholdMeasurement(0.25, 'householdMeasurementPerPortion');
            }
          }
        }, [_c('c-box', {
          attrs: {
            "width": "15px",
            "height": "auto"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-plus.svg'),
            "height": "100%",
            "width": "100%",
            "fill": _vm.isInvalidField(_vm.$v.data.householdMeasurementPerPortion.householdMeasurementPerPortion) ? '#D32737' : '#008C81'
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "input-right-addon-element",
      fn: function fn() {
        return [_c('c-button', {
          attrs: {
            "height": "100%",
            "background-color": "transparent",
            "font-size": "18px",
            "font-weight": "500"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalUnit = 'householdMeasurementPerPortion';
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.data.householdMeasurementPerPortion.householdMeasurementUnitPerPortion) + " "), _c('c-box', {
          attrs: {
            "margin-left": "10px"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-arrow-down.svg'),
            "height": "15",
            "width": "15"
          }
        })], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.data.householdMeasurementPerPortion.householdMeasurementPerPortion,
      callback: function callback($$v) {
        _vm.$set(_vm.data.householdMeasurementPerPortion, "householdMeasurementPerPortion", $$v);
      },
      expression: "data.householdMeasurementPerPortion.householdMeasurementPerPortion"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "gap": "32px"
    }
  }, [_c('BaseInputText', {
    attrs: {
      "label": "Takaran Saji",
      "placeholder": "Masukkan Takaran Saji",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.servingSize.servingSize),
      "invalid-text": _vm.parseErrors('Takaran Saji', _vm.$v.data.servingSize.servingSize),
      "input-right-addon-without-margin": ""
    },
    on: {
      "blur": _vm.$v.data.servingSize.servingSize.$touch,
      "focus": function focus($event) {
        var _vm$data3, _vm$data3$servingSize;

        return _vm.selectAllText($event, (_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : (_vm$data3$servingSize = _vm$data3.servingSize) === null || _vm$data3$servingSize === void 0 ? void 0 : _vm$data3$servingSize.servingSize);
      }
    },
    scopedSlots: _vm._u([{
      key: "input-right-addon-element",
      fn: function fn() {
        return [_c('c-button', {
          attrs: {
            "height": "100%",
            "background-color": "transparent",
            "font-size": "18px",
            "font-weight": "500"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalUnit = 'servingSize';
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.data.servingSize.servingSizeUnit) + " "), _c('c-box', {
          attrs: {
            "margin-left": "10px"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-arrow-down.svg'),
            "height": "15",
            "width": "15"
          }
        })], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.data.servingSize.servingSize,
      callback: function callback($$v) {
        _vm.$set(_vm.data.servingSize, "servingSize", $$v);
      },
      expression: "data.servingSize.servingSize"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Ukuran Rumah Tangga",
      "placeholder": "Masukkan Ukuran Rumah Tangga",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.householdMeasurement.householdMeasurement),
      "invalid-text": _vm.parseErrors('Ukuran Rumah Tangga', _vm.$v.data.householdMeasurement.householdMeasurement),
      "input-right-addon-without-margin": "",
      "is-centered": ""
    },
    on: {
      "blur": _vm.$v.data.householdMeasurement.householdMeasurement.$touch,
      "focus": function focus($event) {
        var _vm$data4, _vm$data4$householdMe;

        return _vm.selectAllText($event, (_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : (_vm$data4$householdMe = _vm$data4.householdMeasurement) === null || _vm$data4$householdMe === void 0 ? void 0 : _vm$data4$householdMe.householdMeasurement);
      }
    },
    scopedSlots: _vm._u([{
      key: "input-left-icon-element",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "as": "button",
            "type": "button",
            "justify-content": "center",
            "align-items": "center",
            "width": "40px",
            "height": "100%",
            "border-right-width": "1px",
            "border-style": "solid",
            "border-color": _vm.isInvalidField(_vm.$v.data.householdMeasurement.householdMeasurement) ? 'danger.400' : 'inherit',
            "border-top-left-radius": "6px",
            "border-bottom-left-radius": "6px",
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.onUpdateHouseholdMeasurement(-0.25, 'householdMeasurement');
            }
          }
        }, [_c('c-box', {
          attrs: {
            "width": "15px",
            "height": "auto"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-minus.svg'),
            "height": "100%",
            "width": "100%",
            "fill": _vm.isInvalidField(_vm.$v.data.householdMeasurement.householdMeasurement) ? '#D32737' : '#008C81'
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "input-right-icon-element",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "as": "button",
            "type": "button",
            "justify-content": "center",
            "align-items": "center",
            "width": "40px",
            "height": "100%",
            "border-left-width": "1px",
            "border-style": "solid",
            "border-color": _vm.isInvalidField(_vm.$v.data.householdMeasurement.householdMeasurement) ? 'danger.400' : 'inherit',
            "border-top-right-radius": "0",
            "border-bottom-right-radius": "0",
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.onUpdateHouseholdMeasurement(0.25, 'householdMeasurement');
            }
          }
        }, [_c('c-box', {
          attrs: {
            "width": "15px",
            "height": "auto"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-plus.svg'),
            "height": "100%",
            "width": "100%",
            "fill": _vm.isInvalidField(_vm.$v.data.householdMeasurement.householdMeasurement) ? '#D32737' : '#008C81'
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "input-right-addon-element",
      fn: function fn() {
        return [_c('c-button', {
          attrs: {
            "height": "100%",
            "background-color": "transparent",
            "font-size": "18px",
            "font-weight": "500"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalUnit = 'householdMeasurement';
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.data.householdMeasurement.householdMeasurementUnit) + " "), _c('c-box', {
          attrs: {
            "margin-left": "10px"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-arrow-down.svg'),
            "height": "15",
            "width": "15"
          }
        })], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.data.householdMeasurement.householdMeasurement,
      callback: function callback($$v) {
        _vm.$set(_vm.data.householdMeasurement, "householdMeasurement", $$v);
      },
      expression: "data.householdMeasurement.householdMeasurement"
    }
  })], 1), _c('c-box', {
    attrs: {
      "padding": "0px 0 16px 0"
    }
  }, [_c('BaseDivider')], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column"
    }
  }, [_vm._l(_vm.data.foodComposition, function (_, i) {
    return _c('c-flex', {
      key: i,
      attrs: {
        "gap": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "flex-grow": "1"
      }
    }, [_c('InputAutocomplete', {
      attrs: {
        "size": "lg",
        "type": "meal-glossary",
        "selection": _vm.data.foodComposition[i].foodName,
        "label": "Komposisi ".concat(i + 1),
        "placeholder": 'Masukkan Komposisi',
        "full-width": ""
      },
      on: {
        "blur": _vm.$v.data.foodGroup.$touch
      },
      model: {
        value: _vm.data.foodComposition[i].foodName,
        callback: function callback($$v) {
          _vm.$set(_vm.data.foodComposition[i], "foodName", $$v);
        },
        expression: "data.foodComposition[i].foodName"
      }
    })], 1), _c('c-box', {
      attrs: {
        "max-width": "240px",
        "flex-shrink": "0"
      }
    }, [_c('BaseInputText', {
      attrs: {
        "label": "Takaran Saji",
        "placeholder": "Masukkan Takaran Saji",
        "full-width": "",
        "input-right-addon-without-margin": ""
      },
      on: {
        "focus": function focus($event) {
          var _vm$data5, _vm$data5$foodComposi;

          return _vm.selectAllText($event, (_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : (_vm$data5$foodComposi = _vm$data5.foodComposition[i]) === null || _vm$data5$foodComposi === void 0 ? void 0 : _vm$data5$foodComposi.servingSize);
        },
        "blur": _vm.$v.data.foodGroup.$touch
      },
      scopedSlots: _vm._u([{
        key: "input-right-addon-element",
        fn: function fn() {
          return [_c('c-button', {
            attrs: {
              "height": "100%",
              "background-color": "transparent",
              "font-size": "18px",
              "font-weight": "500",
              "display": "flex",
              "justify-content": "space-between",
              "align-items": "center",
              "max-width": "170px"
            },
            on: {
              "click": function click() {
                _vm.indexFoodComposition = i;
                _vm.isOpenModalUnit = 'servingSizeComposition';
              }
            }
          }, [_c('c-box', {
            attrs: {
              "flex-grow": "1",
              "overflow": "hidden",
              "text-overflow": "ellipsis",
              "white-space": "nowrap"
            }
          }, [_vm._v(" " + _vm._s(_vm.data.foodComposition[i].servingSizeUnit) + " ")]), _c('c-box', {
            attrs: {
              "flex-shrink": "0",
              "margin-left": "10px"
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-arrow-down.svg'),
              "height": "15",
              "width": "15"
            }
          })], 1)], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.data.foodComposition[i].servingSize,
        callback: function callback($$v) {
          _vm.$set(_vm.data.foodComposition[i], "servingSize", $$v);
        },
        expression: "data.foodComposition[i].servingSize"
      }
    })], 1), _c('c-box', {
      attrs: {
        "width": "fit-content",
        "max-width": "240px",
        "flex-shrink": "0"
      }
    }, [_c('BaseInputText', {
      attrs: {
        "label": "URT",
        "placeholder": "Masukkan URT",
        "full-width": "",
        "input-right-addon-without-margin": ""
      },
      on: {
        "focus": function focus($event) {
          var _vm$data6, _vm$data6$foodComposi;

          return _vm.selectAllText($event, (_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : (_vm$data6$foodComposi = _vm$data6.foodComposition[i]) === null || _vm$data6$foodComposi === void 0 ? void 0 : _vm$data6$foodComposi.householdMeasurement);
        },
        "blur": _vm.$v.data.foodGroup.$touch
      },
      scopedSlots: _vm._u([{
        key: "input-right-addon-element",
        fn: function fn() {
          return [_c('c-button', {
            attrs: {
              "height": "100%",
              "background-color": "transparent",
              "font-size": "18px",
              "font-weight": "500",
              "display": "flex",
              "justify-content": "space-between",
              "align-items": "center",
              "max-width": "170px"
            },
            on: {
              "click": function click() {
                _vm.indexFoodComposition = i;
                _vm.isOpenModalUnit = 'householdMeasurementComposition';
              }
            }
          }, [_c('c-box', {
            attrs: {
              "flex-grow": "1",
              "overflow": "hidden",
              "text-overflow": "ellipsis",
              "white-space": "nowrap"
            }
          }, [_vm._v(" " + _vm._s(_vm.data.foodComposition[i].householdMeasurementUnit) + " ")]), _c('c-box', {
            attrs: {
              "flex-shrink": "0",
              "margin-left": "10px"
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-arrow-down.svg'),
              "height": "15",
              "width": "15"
            }
          })], 1)], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.data.foodComposition[i].householdMeasurement,
        callback: function callback($$v) {
          _vm.$set(_vm.data.foodComposition[i], "householdMeasurement", $$v);
        },
        expression: "data.foodComposition[i].householdMeasurement"
      }
    })], 1), _c('c-button', {
      attrs: {
        "variant": "ghost",
        "variant-color": "danger",
        "margin-top": "40px",
        "width": "40px",
        "height": "40px",
        "padding": "0",
        "flex-shrink": "0"
      },
      on: {
        "click": function click($event) {
          return _vm.onRemoveFoodComposition(i);
        }
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-trash.svg'),
        "height": "20px",
        "width": "20px",
        "fill": "#D32737"
      }
    })], 1)], 1);
  }), _c('BaseButton', {
    attrs: {
      "margin": "auto",
      "size": "large",
      "border-radius": "1000px",
      "variant": "outlined",
      "width": "456px",
      "margin-right": "0px",
      "right-svg-icon": require('@/assets/icons/icon-circle-plus.svg'),
      "right-svg-icon-color": "#008C81",
      "height": ['50px', '62px']
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return function () {
          _vm.$v.data.foodGroup.$touch();

          _vm.data.foodComposition.push({
            foodName: '',
            servingSize: null,
            servingSizeUnit: '',
            householdMeasurement: null,
            householdMeasurementUnit: ''
          });
        }.apply(null, arguments);
      }
    }
  }, [_vm._v(" Tambah Komposisi ")])], 2), _c('c-box', {
    attrs: {
      "margin": "30px 0 16px 0"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "primary.400"
    }
  }, [_vm._v(" Informasi Nilai Gizi ")]), _c('BaseDivider')], 1), _c('BaseInputText', {
    attrs: {
      "label": "Energi",
      "placeholder": "Masukkan Energi",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.calories.amount),
      "invalid-text": _vm.parseErrors('Energi', _vm.$v.data.nutritionContent.calories.amount),
      "input-right-addon": (_vm$data$nutritionCon = _vm.data.nutritionContent) === null || _vm$data$nutritionCon === void 0 ? void 0 : (_vm$data$nutritionCon2 = _vm$data$nutritionCon.calories) === null || _vm$data$nutritionCon2 === void 0 ? void 0 : _vm$data$nutritionCon2.unit
    },
    on: {
      "blur": _vm.$v.data.nutritionContent.calories.amount.$touch,
      "focus": function focus($event) {
        var _vm$data7, _vm$data7$nutritionCo, _vm$data7$nutritionCo2;

        return _vm.selectAllText($event, (_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : (_vm$data7$nutritionCo = _vm$data7.nutritionContent) === null || _vm$data7$nutritionCo === void 0 ? void 0 : (_vm$data7$nutritionCo2 = _vm$data7$nutritionCo.calories) === null || _vm$data7$nutritionCo2 === void 0 ? void 0 : _vm$data7$nutritionCo2.amount);
      }
    },
    model: {
      value: _vm.data.nutritionContent.calories.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.data.nutritionContent.calories, "amount", $$v);
      },
      expression: "data.nutritionContent.calories.amount"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Protein",
      "placeholder": "Masukkan Protein",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.protein.amount),
      "invalid-text": _vm.parseErrors('Protein', _vm.$v.data.nutritionContent.protein.amount),
      "input-right-addon": _vm.data.nutritionContent.protein.unit
    },
    on: {
      "blur": _vm.$v.data.nutritionContent.protein.amount.$touch,
      "focus": function focus($event) {
        var _vm$data8, _vm$data8$nutritionCo, _vm$data8$nutritionCo2;

        return _vm.selectAllText($event, (_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : (_vm$data8$nutritionCo = _vm$data8.nutritionContent) === null || _vm$data8$nutritionCo === void 0 ? void 0 : (_vm$data8$nutritionCo2 = _vm$data8$nutritionCo.protein) === null || _vm$data8$nutritionCo2 === void 0 ? void 0 : _vm$data8$nutritionCo2.amount);
      }
    },
    model: {
      value: _vm.data.nutritionContent.protein.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.data.nutritionContent.protein, "amount", $$v);
      },
      expression: "data.nutritionContent.protein.amount"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Lemak",
      "placeholder": "Masukkan Lemak",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.fat.fat.amount),
      "invalid-text": _vm.parseErrors('Lemak', _vm.$v.data.nutritionContent.fat.fat.amount),
      "input-right-addon": _vm.data.nutritionContent.fat.fat.unit
    },
    on: {
      "blur": _vm.$v.data.nutritionContent.fat.fat.amount.$touch,
      "focus": function focus($event) {
        var _vm$data9, _vm$data9$nutritionCo, _vm$data9$nutritionCo2, _vm$data9$nutritionCo3;

        return _vm.selectAllText($event, (_vm$data9 = _vm.data) === null || _vm$data9 === void 0 ? void 0 : (_vm$data9$nutritionCo = _vm$data9.nutritionContent) === null || _vm$data9$nutritionCo === void 0 ? void 0 : (_vm$data9$nutritionCo2 = _vm$data9$nutritionCo.fat) === null || _vm$data9$nutritionCo2 === void 0 ? void 0 : (_vm$data9$nutritionCo3 = _vm$data9$nutritionCo2.fat) === null || _vm$data9$nutritionCo3 === void 0 ? void 0 : _vm$data9$nutritionCo3.amount);
      }
    },
    model: {
      value: _vm.data.nutritionContent.fat.fat.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.data.nutritionContent.fat.fat, "amount", $$v);
      },
      expression: "data.nutritionContent.fat.fat.amount"
    }
  }), _c('TestFieldArray', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$data10, _vm$data10$nutritionC, _vm$data10$nutritionC2;

        var push = _ref.push,
            remove = _ref.remove;
        return [_vm._l(((_vm$data10 = _vm.data) === null || _vm$data10 === void 0 ? void 0 : (_vm$data10$nutritionC = _vm$data10.nutritionContent) === null || _vm$data10$nutritionC === void 0 ? void 0 : (_vm$data10$nutritionC2 = _vm$data10$nutritionC.fat) === null || _vm$data10$nutritionC2 === void 0 ? void 0 : _vm$data10$nutritionC2.sub) || [], function (item, i) {
          return _c('c-box', {
            key: i,
            attrs: {
              "margin-top": "16px",
              "_first": {
                marginTop: '0px'
              }
            }
          }, [_c('c-flex', {
            attrs: {
              "gap": "32px",
              "align-items": "start"
            }
          }, [_c('BaseInputAutocomplete', {
            attrs: {
              "value": item.name,
              "placeholder": "Pilih Turunan",
              "full-width": "",
              "is-required": "",
              "options": Object.values(_vm.parseNutrition.listFatSubs) || [],
              "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.fat.sub.$each[i].name),
              "invalid-text": _vm.parseErrors('Turunan', _vm.$v.data.nutritionContent.fat.sub.$each[i].name)
            },
            on: {
              "input": function input(value) {
                var _vm$parseNutrition, _vm$parseNutrition$li, _vm$parseNutrition$li2;

                item.name = value;
                item.unit = (_vm$parseNutrition = _vm.parseNutrition) === null || _vm$parseNutrition === void 0 ? void 0 : (_vm$parseNutrition$li = _vm$parseNutrition.listFatSubs) === null || _vm$parseNutrition$li === void 0 ? void 0 : (_vm$parseNutrition$li2 = _vm$parseNutrition$li[value]) === null || _vm$parseNutrition$li2 === void 0 ? void 0 : _vm$parseNutrition$li2.unit;
              },
              "blur": _vm.$v.data.nutritionContent.fat.sub.$each[i].name.$touch
            }
          }), _c('BaseInputText', {
            attrs: {
              "placeholder": "Jumlah",
              "full-width": "",
              "is-required": false,
              "input-right-addon": item === null || item === void 0 ? void 0 : item.unit,
              "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.fat.sub.$each[i].amount),
              "invalid-text": _vm.parseErrors('Jumlah', _vm.$v.data.nutritionContent.fat.sub.$each[i].amount)
            },
            on: {
              "blur": _vm.$v.data.nutritionContent.fat.sub.$each[i].amount.$touch,
              "focus": function focus($event) {
                return _vm.selectAllText($event, item === null || item === void 0 ? void 0 : item.amount);
              }
            },
            model: {
              value: item.amount,
              callback: function callback($$v) {
                _vm.$set(item, "amount", $$v);
              },
              expression: "item.amount"
            }
          }), _c('c-button', {
            attrs: {
              "margin-top": "10px",
              "background-color": "transparent"
            },
            on: {
              "click": function click($event) {
                return remove(_vm.data.nutritionContent.fat.sub, i);
              }
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-trash.svg'),
              "height": "38px",
              "width": "38px",
              "fill": "#D32737"
            }
          })], 1)], 1)], 1);
        }), _c('c-flex', {
          attrs: {
            "justify-content": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "margin": "auto",
            "mt": "-15px",
            "size": "small",
            "border-radius": "1000px",
            "variant": "outlined",
            "width": "100%",
            "right-svg-icon": require('@/assets/icons/icon-circle-plus.svg'),
            "right-svg-icon-color": "#008C81",
            "height": ['40px']
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return push(_vm.data.nutritionContent.fat.sub, {
                name: '',
                amount: 0,
                unit: ''
              });
            }
          }
        }, [_vm._v(" Tambah Turunan ")])], 1)];
      }
    }])
  }), _c('c-box', {
    attrs: {
      "margin-top": "30px"
    }
  }, [_c('BaseInputText', {
    attrs: {
      "label": "Karbohidrat",
      "placeholder": "Masukkan Karbohidrat",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.carbohydrate.carbohydrate.amount),
      "invalid-text": _vm.parseErrors('Karbohidrat', _vm.$v.data.nutritionContent.carbohydrate.carbohydrate.amount),
      "input-right-addon": (_vm$data11 = _vm.data) === null || _vm$data11 === void 0 ? void 0 : (_vm$data11$nutritionC = _vm$data11.nutritionContent) === null || _vm$data11$nutritionC === void 0 ? void 0 : (_vm$data11$nutritionC2 = _vm$data11$nutritionC.carbohydrate) === null || _vm$data11$nutritionC2 === void 0 ? void 0 : (_vm$data11$nutritionC3 = _vm$data11$nutritionC2.carbohydrate) === null || _vm$data11$nutritionC3 === void 0 ? void 0 : _vm$data11$nutritionC3.unit
    },
    on: {
      "blur": _vm.$v.data.nutritionContent.carbohydrate.carbohydrate.amount.$touch,
      "focus": function focus($event) {
        var _vm$data12, _vm$data12$nutritionC, _vm$data12$nutritionC2, _vm$data12$nutritionC3;

        return _vm.selectAllText($event, (_vm$data12 = _vm.data) === null || _vm$data12 === void 0 ? void 0 : (_vm$data12$nutritionC = _vm$data12.nutritionContent) === null || _vm$data12$nutritionC === void 0 ? void 0 : (_vm$data12$nutritionC2 = _vm$data12$nutritionC.carbohydrate) === null || _vm$data12$nutritionC2 === void 0 ? void 0 : (_vm$data12$nutritionC3 = _vm$data12$nutritionC2.carbohydrate) === null || _vm$data12$nutritionC3 === void 0 ? void 0 : _vm$data12$nutritionC3.amount);
      }
    },
    model: {
      value: _vm.data.nutritionContent.carbohydrate.carbohydrate.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.data.nutritionContent.carbohydrate.carbohydrate, "amount", $$v);
      },
      expression: "data.nutritionContent.carbohydrate.carbohydrate.amount"
    }
  })], 1), _c('TestFieldArray', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _vm$data13, _vm$data13$nutritionC, _vm$data13$nutritionC2;

        var push = _ref2.push,
            remove = _ref2.remove;
        return [_vm._l(((_vm$data13 = _vm.data) === null || _vm$data13 === void 0 ? void 0 : (_vm$data13$nutritionC = _vm$data13.nutritionContent) === null || _vm$data13$nutritionC === void 0 ? void 0 : (_vm$data13$nutritionC2 = _vm$data13$nutritionC.carbohydrate) === null || _vm$data13$nutritionC2 === void 0 ? void 0 : _vm$data13$nutritionC2.sub) || [], function (item, i) {
          return _c('c-box', {
            key: i,
            attrs: {
              "margin-top": "16px",
              "_first": {
                marginTop: '0px'
              }
            }
          }, [_c('c-flex', {
            attrs: {
              "gap": "32px",
              "align-items": "start"
            }
          }, [_c('BaseInputAutocomplete', {
            attrs: {
              "value": item.name,
              "placeholder": "Pilih Turunan",
              "full-width": "",
              "is-required": "",
              "options": Object.values(_vm.parseNutrition.listCarbohydrateSubs) || [],
              "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.carbohydrate.sub.$each[i].name),
              "invalid-text": _vm.parseErrors('Turunan', _vm.$v.data.nutritionContent.carbohydrate.sub.$each[i].name)
            },
            on: {
              "input": function input(value) {
                var _vm$parseNutrition2, _vm$parseNutrition2$l, _vm$parseNutrition2$l2;

                item.name = value;
                item.unit = (_vm$parseNutrition2 = _vm.parseNutrition) === null || _vm$parseNutrition2 === void 0 ? void 0 : (_vm$parseNutrition2$l = _vm$parseNutrition2.listCarbohydrateSubs) === null || _vm$parseNutrition2$l === void 0 ? void 0 : (_vm$parseNutrition2$l2 = _vm$parseNutrition2$l[value]) === null || _vm$parseNutrition2$l2 === void 0 ? void 0 : _vm$parseNutrition2$l2.unit;
              },
              "blur": _vm.$v.data.nutritionContent.carbohydrate.sub.$each[i].name.$touch
            }
          }), _c('BaseInputText', {
            attrs: {
              "placeholder": "Jumlah",
              "full-width": "",
              "is-required": false,
              "input-right-addon": item === null || item === void 0 ? void 0 : item.unit,
              "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.carbohydrate.sub.$each[i].amount),
              "invalid-text": _vm.parseErrors('Jumlah', _vm.$v.data.nutritionContent.carbohydrate.sub.$each[i].amount)
            },
            on: {
              "blur": _vm.$v.data.nutritionContent.carbohydrate.sub.$each[i].amount.$touch,
              "focus": function focus($event) {
                return _vm.selectAllText($event, item === null || item === void 0 ? void 0 : item.amount);
              }
            },
            model: {
              value: item.amount,
              callback: function callback($$v) {
                _vm.$set(item, "amount", $$v);
              },
              expression: "item.amount"
            }
          }), _c('c-button', {
            attrs: {
              "margin-top": "10px",
              "background-color": "transparent"
            },
            on: {
              "click": function click($event) {
                return remove(_vm.data.nutritionContent.carbohydrate.sub, i);
              }
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-trash.svg'),
              "height": "38px",
              "width": "38px",
              "fill": "#D32737"
            }
          })], 1)], 1)], 1);
        }), _c('c-flex', {
          attrs: {
            "justify-content": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "margin": "auto",
            "mt": "-15px",
            "size": "small",
            "border-radius": "1000px",
            "variant": "outlined",
            "width": "100%",
            "right-svg-icon": require('@/assets/icons/icon-circle-plus.svg'),
            "right-svg-icon-color": "#008C81",
            "height": ['40px']
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return push(_vm.data.nutritionContent.carbohydrate.sub, {
                name: '',
                amount: 0,
                unit: ''
              });
            }
          }
        }, [_vm._v(" Tambah Turunan ")])], 1)];
      }
    }])
  }), _c('c-box', {
    attrs: {
      "margin-top": "30px"
    }
  }, [_c('BaseInputText', {
    attrs: {
      "label": "Kolesterol",
      "placeholder": "Masukkan Kolesterol",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.cholesterol.amount),
      "invalid-text": _vm.parseErrors('Kolesterol', _vm.$v.data.nutritionContent.cholesterol.amount),
      "input-right-addon": _vm.data.nutritionContent.cholesterol.unit
    },
    on: {
      "blur": _vm.$v.data.nutritionContent.cholesterol.amount.$touch,
      "focus": function focus($event) {
        var _vm$data14, _vm$data14$nutritionC, _vm$data14$nutritionC2;

        return _vm.selectAllText($event, (_vm$data14 = _vm.data) === null || _vm$data14 === void 0 ? void 0 : (_vm$data14$nutritionC = _vm$data14.nutritionContent) === null || _vm$data14$nutritionC === void 0 ? void 0 : (_vm$data14$nutritionC2 = _vm$data14$nutritionC.cholesterol) === null || _vm$data14$nutritionC2 === void 0 ? void 0 : _vm$data14$nutritionC2.amount);
      }
    },
    model: {
      value: _vm.data.nutritionContent.cholesterol.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.data.nutritionContent.cholesterol, "amount", $$v);
      },
      expression: "data.nutritionContent.cholesterol.amount"
    }
  })], 1), _c('BaseInputText', {
    attrs: {
      "label": "Natrium (garam)",
      "placeholder": "Masukkan Natrium (garam)",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.natrium.amount),
      "invalid-text": _vm.parseErrors('Natrium (garam)', _vm.$v.data.nutritionContent.natrium.amount),
      "input-right-addon": (_vm$data15 = _vm.data) === null || _vm$data15 === void 0 ? void 0 : (_vm$data15$nutritionC = _vm$data15.nutritionContent) === null || _vm$data15$nutritionC === void 0 ? void 0 : (_vm$data15$nutritionC2 = _vm$data15$nutritionC.natrium) === null || _vm$data15$nutritionC2 === void 0 ? void 0 : _vm$data15$nutritionC2.unit
    },
    on: {
      "blur": _vm.$v.data.nutritionContent.natrium.amount.$touch,
      "focus": function focus($event) {
        var _vm$data16, _vm$data16$nutritionC, _vm$data16$nutritionC2;

        return _vm.selectAllText($event, (_vm$data16 = _vm.data) === null || _vm$data16 === void 0 ? void 0 : (_vm$data16$nutritionC = _vm$data16.nutritionContent) === null || _vm$data16$nutritionC === void 0 ? void 0 : (_vm$data16$nutritionC2 = _vm$data16$nutritionC.natrium) === null || _vm$data16$nutritionC2 === void 0 ? void 0 : _vm$data16$nutritionC2.amount);
      }
    },
    model: {
      value: _vm.data.nutritionContent.natrium.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.data.nutritionContent.natrium, "amount", $$v);
      },
      expression: "data.nutritionContent.natrium.amount"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Zat Besi",
      "placeholder": "Masukkan Zat Besi",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.iron.amount),
      "invalid-text": _vm.parseErrors('Zat Besi', _vm.$v.data.nutritionContent.iron.amount),
      "input-right-addon": _vm.data.nutritionContent.iron.unit
    },
    on: {
      "blur": _vm.$v.data.nutritionContent.iron.amount.$touch,
      "focus": function focus($event) {
        var _vm$data17, _vm$data17$nutritionC, _vm$data17$nutritionC2;

        return _vm.selectAllText($event, (_vm$data17 = _vm.data) === null || _vm$data17 === void 0 ? void 0 : (_vm$data17$nutritionC = _vm$data17.nutritionContent) === null || _vm$data17$nutritionC === void 0 ? void 0 : (_vm$data17$nutritionC2 = _vm$data17$nutritionC.iron) === null || _vm$data17$nutritionC2 === void 0 ? void 0 : _vm$data17$nutritionC2.amount);
      }
    },
    model: {
      value: _vm.data.nutritionContent.iron.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.data.nutritionContent.iron, "amount", $$v);
      },
      expression: "data.nutritionContent.iron.amount"
    }
  }), _c('TestFieldArray', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var _vm$data18, _vm$data18$nutritionC;

        var push = _ref3.push,
            remove = _ref3.remove;
        return [_vm._l(((_vm$data18 = _vm.data) === null || _vm$data18 === void 0 ? void 0 : (_vm$data18$nutritionC = _vm$data18.nutritionContent) === null || _vm$data18$nutritionC === void 0 ? void 0 : _vm$data18$nutritionC.additonalNutritionContent) || [], function (item, i) {
          return _c('c-box', {
            key: i,
            attrs: {
              "margin-top": "16px",
              "_first": {
                marginTop: '0px'
              }
            }
          }, [_c('c-flex', {
            attrs: {
              "gap": "32px",
              "align-items": "start"
            }
          }, [_c('BaseInputAutocomplete', {
            attrs: {
              "value": item.name,
              "placeholder": "Masukkan Nama Gizi",
              "full-width": "",
              "is-required": "",
              "options": Object.values(_vm.parseNutrition.listOptionalNutritions) || [],
              "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.additonalNutritionContent.$each[i].name),
              "invalid-text": _vm.parseErrors('Nama Gizi', _vm.$v.data.nutritionContent.additonalNutritionContent.$each[i].name)
            },
            on: {
              "input": function input(value) {
                var _vm$parseNutrition3, _vm$parseNutrition3$l, _vm$parseNutrition3$l2;

                item.name = value;
                item.unit = (_vm$parseNutrition3 = _vm.parseNutrition) === null || _vm$parseNutrition3 === void 0 ? void 0 : (_vm$parseNutrition3$l = _vm$parseNutrition3.listOptionalNutritions) === null || _vm$parseNutrition3$l === void 0 ? void 0 : (_vm$parseNutrition3$l2 = _vm$parseNutrition3$l[value]) === null || _vm$parseNutrition3$l2 === void 0 ? void 0 : _vm$parseNutrition3$l2.unit;
              },
              "blur": _vm.$v.data.nutritionContent.additonalNutritionContent.$each[i].name.$touch
            }
          }), _c('BaseInputText', {
            attrs: {
              "placeholder": "Masukkan Nilai Gizi",
              "full-width": "",
              "is-required": false,
              "input-right-addon": item.unit,
              "is-invalid": _vm.isInvalidField(_vm.$v.data.nutritionContent.additonalNutritionContent.$each[i].amount),
              "invalid-text": _vm.parseErrors('Nilai Gizi', _vm.$v.data.nutritionContent.additonalNutritionContent.$each[i].amount)
            },
            on: {
              "blur": _vm.$v.data.nutritionContent.additonalNutritionContent.$each[i].amount.$touch,
              "focus": function focus($event) {
                return _vm.selectAllText($event, item === null || item === void 0 ? void 0 : item.amount);
              }
            },
            model: {
              value: item.amount,
              callback: function callback($$v) {
                _vm.$set(item, "amount", $$v);
              },
              expression: "item.amount"
            }
          }), _c('c-button', {
            attrs: {
              "margin-top": "10px",
              "background-color": "transparent"
            },
            on: {
              "click": function click($event) {
                return remove(_vm.data.nutritionContent.additonalNutritionContent, i);
              }
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-trash.svg'),
              "height": "38px",
              "width": "38px",
              "fill": "#D32737"
            }
          })], 1)], 1)], 1);
        }), _c('c-flex', {
          attrs: {
            "justify-content": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "margin": "auto",
            "size": "large",
            "border-radius": "1000px",
            "variant": "outlined",
            "width": "100%",
            "right-svg-icon": require('@/assets/icons/icon-circle-plus.svg'),
            "right-svg-icon-color": "#008C81",
            "height": ['50px', '62px']
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return push(_vm.data.nutritionContent.additonalNutritionContent, {
                name: '',
                amount: 0,
                unit: ''
              });
            }
          }
        }, [_vm._v(" Tambah Nilai Gizi Opsional ")])], 1)];
      }
    }])
  }), _c('c-box', {
    attrs: {
      "margin": "30px 0 16px 0"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "primary.400"
    }
  }, [_vm._v(" Zat Gizi Dominan ")]), _c('BaseDivider')], 1), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "padding": "0 0 4px 8px",
      "color": _vm.isInvalidField(_vm.$v.data.mainNutritionContent) ? '#D32737' : !!_vm.mainNutritionContentSingleText ? 'primary.400' : 'black'
    }
  }, [_vm._v(" Zat Gizi Dominan "), _c('c-text', {
    attrs: {
      "as": "span",
      "color": "danger.400",
      "margin-left": "4px"
    }
  }, [_vm._v(" * ")])], 1), _c('c-button', {
    staticStyle: {
      "text-wrap": "pretty"
    },
    attrs: {
      "border-radius": "8px",
      "width": "100%",
      "height": "62px",
      "border": "1px solid #E5E7EB",
      "background-color": "white",
      "font-weight": "500",
      "font-size": "18px",
      "color": !!_vm.mainNutritionContentSingleText ? 'primary.400' : '#ACB8C8',
      "justify-content": "flex-start",
      "padding": "0 20px",
      "text-align": "left"
    },
    on: {
      "click": function click($event) {
        _vm.isOpenModalZatGiziDominan = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.mainNutritionContentSingleText ? _vm.mainNutritionContentSingleText : 'Masukkan Zat Gizi Dominan') + " ")]), _c('c-box', {
    attrs: {
      "padding-top": "4px"
    }
  }, [_c('c-text', {
    attrs: {
      "as": "span",
      "color": "#D32737"
    }
  }, [_vm._v(" " + _vm._s(_vm.isInvalidField(_vm.$v.data.mainNutritionContent) ? "● Zat Gizi Dominan tidak boleh kosong" : '') + " ")])], 1), _c('c-box', {
    attrs: {
      "margin": "40px 0 16px 0"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "primary.400"
    }
  }, [_vm._v(" Lainnya "), _c('c-text', {
    attrs: {
      "as": "span",
      "font-weight": "400",
      "font-size": "14px",
      "color": "neutral.darkGray"
    }
  }, [_vm._v(" (Opsional) ")])], 1), _c('BaseDivider')], 1), _c('BaseInputAutocomplete', {
    attrs: {
      "label": "Makanan Khas",
      "placeholder": "Masukkan Makanan Khas",
      "full-width": "",
      "can-manual-input": "",
      "options": _vm.optionCuisines
    },
    on: {
      "trigger-change": function triggerChange(_, isValueAlreadyExist) {
        _vm.data.isNewCuisine = isValueAlreadyExist ? '0' : '1';
      },
      "blur": _vm.$v.data.foodGroup.$touch
    },
    model: {
      value: _vm.data.other.cuisine,
      callback: function callback($$v) {
        _vm.$set(_vm.data.other, "cuisine", $$v);
      },
      expression: "data.other.cuisine"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Tag Lainnya",
      "placeholder": "Masukkan Tag Lainnya",
      "full-width": ""
    },
    on: {
      "blur": _vm.$v.data.foodName.$touch
    },
    model: {
      value: _vm.data.other.tag,
      callback: function callback($$v) {
        _vm.$set(_vm.data.other, "tag", $$v);
      },
      expression: "data.other.tag"
    }
  }), _c('c-flex', {
    attrs: {
      "margin": "auto",
      "max-width": "600px",
      "mt": "28px",
      "justify-content": "space-between",
      "gap": "20px"
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "border-radius": "1000px",
      "width": "100%",
      "variant": "outlined",
      "disabled": _vm.isSubmitting
    },
    on: {
      "click": _vm.cancelEditOrAdd
    }
  }, [_vm._v(" Batal ")]), _c('BaseButton', {
    attrs: {
      "size": "large",
      "border-radius": "1000px",
      "width": "100%",
      "disabled": _vm.$v.$invalid || !_vm.$v.$anyDirty,
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": function click($event) {
        _vm.isOpenModalConfirmationEditSchema = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isEditPage ? 'Simpan' : 'Tambah') + " ")])], 1)], 1)], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalConfirmationEditSchema,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$data19;

        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.isEditPage ? "Apakah anda yakin ingin melakukan perubahan data nilai gizi \u201C".concat((_vm$data19 = _vm.data) === null || _vm$data19 === void 0 ? void 0 : _vm$data19.foodName, "\u201D ?") : 'Apakah anda yakin data yang anda isikan sudah benar?') + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalConfirmationEditSchema = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.onSubmit
          }
        }, [_vm._v(" Simpan ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalSuccessEditOrAdd,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/success-image.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.isEditPage ? 'Data nilai gizi berhasil diubah!' : 'Nilai gizi berhasil ditambahkan') + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: 'admin.glossaries'
              });
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalCancelEditOrAdd,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah anda yakin ingin membatalkan pengisian Nilai Gizi? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalCancelEditOrAdd = false;
            }
          }
        }, [_vm._v(" Kembali ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-trash.svg'),
            "right-svg-icon-color": "white",
            "color": "danger",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.back();
            }
          }
        }, [_vm._v(" Ya ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": Boolean(_vm.isOpenModalUnit),
      "close-on-overlay-click": false,
      "with-button-close": false,
      "has-header": false
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('c-accordion', [_c('c-accordion-item', {
          attrs: {
            "border-top-width": "0"
          }
        }, [_c('c-accordion-header', [_c('c-box', {
          attrs: {
            "flex": "1",
            "text-align": "left",
            "font-weight": "500",
            "font-size": "18px",
            "color": "primary.400",
            "padding": "8px"
          }
        }, [_vm._v(" " + _vm._s(['servingSize', 'servingSizePerPortion', 'servingSizeComposition'].includes(_vm.isOpenModalUnit) ? 'Takaran Saji' : 'Ukuran Rumah Tangga') + " ")]), _c('c-accordion-icon')], 1), _c('c-accordion-panel', {
          attrs: {
            "padding": "4px 0",
            "height": "50vmin",
            "overflow": "auto"
          }
        }, [_c('c-radio-button-group', {
          attrs: {
            "display": "flex",
            "flex-direction": "column"
          },
          model: {
            value: _vm.selectedUnit,
            callback: function callback($$v) {
              _vm.selectedUnit = $$v;
            },
            expression: "selectedUnit"
          }
        }, _vm._l(['servingSize', 'servingSizePerPortion', 'servingSizeComposition'].includes(_vm.isOpenModalUnit) ? _vm.optionServingSizeUnit || [] : ['householdMeasurement', 'householdMeasurementPerPortion', 'householdMeasurementComposition'].includes(_vm.isOpenModalUnit) ? _vm.optionHouseholdMeasurement || [] : [], function (item) {
          return _c('CustomRadio', {
            key: item.id,
            attrs: {
              "size": "lg",
              "value": item.id,
              "variant-color": "primary"
            }
          }, [_vm._v(" " + _vm._s(item.name) + " ")]);
        }), 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": _vm.cancelChangUnit
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "disabled": !_vm.selectedUnit
          },
          on: {
            "click": _vm.changeUnit
          }
        }, [_vm._v(" Terapkan ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalZatGiziDominan,
      "close-on-overlay-click": false,
      "with-button-close": false,
      "has-header": false,
      "size": "xl"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('c-accordion', {
          attrs: {
            "allow-toggle": ""
          }
        }, [_c('c-accordion-item', {
          attrs: {
            "border-top-width": "0"
          }
        }, [_c('c-accordion-header', [_c('c-box', {
          attrs: {
            "flex": "1",
            "text-align": "left",
            "font-weight": "500",
            "font-size": "18px",
            "color": "primary.400",
            "padding": "8px"
          }
        }, [_vm._v(" Zat Gizi Dominan ")]), _c('c-accordion-icon')], 1), _c('c-accordion-panel', {
          attrs: {
            "padding": "8px 16px"
          }
        }, [_c('Draggable', _vm._b({
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '8px'
            },
            expression: "{\n                display: 'grid',\n                gridTemplateColumns: 'repeat(3, 1fr)',\n                gap: '8px',\n              }"
          }],
          attrs: {
            "list": _vm.list
          }
        }, 'Draggable', _vm.dragOptions, false), _vm._l(_vm.list, function (element, idx) {
          return _c('c-flex', {
            key: element.name,
            attrs: {
              "background-color": "primary.50",
              "padding": "10px 16px",
              "align-items": "center",
              "border-radius": "calc(infinity * 1px)",
              "gap": "6px"
            }
          }, [_vm._v(" " + _vm._s(idx + 1) + ". " + _vm._s(element.value) + " "), _c('c-box', {
            attrs: {
              "as": "span",
              "display": "inline-block",
              "margin-left": "auto"
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-drag-indicator.svg'),
              "height": "22",
              "width": "22",
              "fill": "#008C81"
            }
          })], 1)], 1);
        }), 1)], 1)], 1)], 1), _c('c-flex', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "height": "50vmin",
            "overflow": "auto"
          }
        }, [_vm._l(Object.keys(_vm.optionMainNutrition), function (checkedItem) {
          return _c('CustomCheckbox', {
            key: checkedItem,
            attrs: {
              "is-checked": _vm.optionMainNutrition[checkedItem].checked,
              "is-disabled": false
            },
            on: {
              "change": function change(isChecked) {
                return _vm.checkedMainNutrition(isChecked, checkedItem);
              }
            }
          }, [_vm.optionMainNutrition[checkedItem]._new ? _c('c-input', {
            attrs: {
              "margin-right": "16px",
              "font-size": "18px",
              "font-weight": "500"
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
              }
            },
            model: {
              value: _vm.optionMainNutrition[checkedItem].value,
              callback: function callback($$v) {
                _vm.$set(_vm.optionMainNutrition[checkedItem], "value", $$v);
              },
              expression: "optionMainNutrition[checkedItem].value"
            }
          }) : _c('c-text', {
            attrs: {
              "as": "span"
            }
          }, [_vm._v(" " + _vm._s(_vm.optionMainNutrition[checkedItem].value) + " ")])], 1);
        }), _c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "0 16px 10px 16px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "margin": "auto",
            "size": "small",
            "border-radius": "1000px",
            "variant": "outlined",
            "width": "100%",
            "margin-top": "16px",
            "right-svg-icon": require('@/assets/icons/icon-plus.svg'),
            "right-svg-icon-color": "#008C81"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return function () {
                var _Object$assign;

                _vm.optionMainNutrition = Object.assign({}, _vm.optionMainNutrition, (_Object$assign = {}, _Object$assign["mainNutrition".concat(Object.keys(_vm.optionMainNutrition).length + 1)] = {
                  id: Math.random(),
                  _new: true,
                  value: '',
                  checked: false
                }, _Object$assign));
              }.apply(null, arguments);
            }
          }
        }, [_vm._v(" Tambah Zat Gizi Dominan ")])], 1)], 2)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": _vm.cancelChangeMainNutrition
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.changeMainNutrition
          }
        }, [_vm._v(" Terapkan ")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }